/* eslint-disable no-underscore-dangle */
import { isType } from '@nubank/nuds-web/utils/isType/isType';

import { getDiscoveryUrl, getInviterUrl } from '@nubank/www-latam-commons/utils/environmentUtils';
import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { getMarketingData, getMarketingParameters } from '@nubank/www-latam-commons/utils/marketingUtils';

export async function getInviter(inviteId) {
  if (!inviteId) {
    throw new Error('No invite id');
  }

  const inviterUrl = getInviterUrl();

  const slugResponse = await fetchJson(inviterUrl.replace(/:slug/, inviteId));

  if (!slugResponse || !slugResponse.redirect) {
    throw new Error('Slug fetch response unexpected');
  }

  return fetchJson(slugResponse.redirect);
}

async function getInviterRegistrationUrl({ discoveryFields }, inviteId) {
  const { inviter } = discoveryFields;

  const inviterResponse = await getInviter(inviteId);

  // eslint-disable-next-line no-underscore-dangle
  const linkToRegister = inviterResponse._links && inviterResponse._links[inviter];
  if (!linkToRegister || !linkToRegister.href) {
    throw new Error(`Invalid inviter field: ${inviter}`);
  }

  return linkToRegister.href;
}

async function getUnsolicitedRegistrationUrl({ discoveryFields }) {
  const { unsolicited } = discoveryFields;

  const discoveryFetch = await fetchJson(getDiscoveryUrl());

  return discoveryFetch[unsolicited];
}

async function getRegistrationUrl(prospectType) {
  const marketingParameters = getMarketingParameters();
  const maybeInviteId = marketingParameters.id;

  try {
    return await getInviterRegistrationUrl(prospectType, maybeInviteId);
  } catch {
    return getUnsolicitedRegistrationUrl(prospectType);
  }
}

const fieldErrorsDictionary = [
  {
    keyName: 'name',
    matcher: /:name/m,
  },
  {
    keyName: 'taxId',
    matcher: /:cpf/m,
  },
  {
    keyName: 'email',
    matcher: /:email/m,
  },
  {
    keyName: 'dob',
    matcher: /:dob/m,
  },
  {
    keyName: 'billingAddressPostcode',
    matcher: /:billing-address-postcode/m,
  },
  {
    keyName: 'billingAddressState',
    matcher: /:billing-address-state/m,
  },
  {
    keyName: 'billingAddressCity',
    matcher: /:billing-address-city/m,
  },
  {
    keyName: 'billingAddressLocality',
    matcher: /:billing-address-locality/m,
  },
  {
    keyName: 'billingAddressLine1',
    matcher: /:billing-address-line1/m,
  },
  {
    keyName: 'billingAddressNumber',
    matcher: /:billing-address-number/m,
  },
  {
    keyName: 'billingAddressLine2',
    matcher: /:billing-address-line2/m,
  },
];

const mapFieldErrors = errorString => (fieldsWithError, { matcher, keyName }) => {
  if (matcher.test(errorString)) {
    return {
      ...fieldsWithError,

      [keyName]: true,
    };
  }

  return fieldsWithError;
};

export async function registerProspect(prospectType, prospect, additionalParameters) {
  const body = {
    prospect,
    marketing: additionalParameters
      ? getMarketingData({ additionalParameters }) : getMarketingData(),
    ...(additionalParameters.opt_in !== undefined && { 'whatsapp-opt-in': additionalParameters.opt_in }),
    ...(additionalParameters.authorization_request_id !== undefined && { 'authorization-request-id': additionalParameters.authorization_request_id }),
    ...(additionalParameters.data_policy_documents_signed !== undefined && { 'data-policy-documents-signed': additionalParameters.data_policy_documents_signed }),
  };

  try {
    const registrationUrl = await getRegistrationUrl(prospectType);

    const response = await fetchJson(registrationUrl, {
      method: 'POST',
      body,
    });

    const hasThrottle = response.error
      && response.error.toLowerCase
      && response.error.toLowerCase() === 'ok';

    const hasRealtimeAnalysis = Boolean(
      response._links && (response._links.analysis || response._links.approved_products),
    );

    const link = hasRealtimeAnalysis ? (response._links.analysis || response._links.approved_products).href : '';
    const publishedOffersLink = response._links?.published_offers?.href;

    return {
      marketingId: response.marketing_id,
      prospectId: response.prospect_id,
      hasThrottle,
      hasRealtimeAnalysis,
      realtimeUrl: link,
      publishedOffersLink,
    };
  } catch (error) {
    const errorString = await error.response.text();

    const responseErrors = fieldErrorsDictionary.reduce(mapFieldErrors(errorString), {});

    if (!Object.keys(responseErrors).length) {
      responseErrors.generic = true;
    }

    throw responseErrors;
  }
}

export async function getPolling(url, activeFinancialGoals) {
  try {
    if (activeFinancialGoals) {
      const template = await fetchJson(url);
      return template;
    }

    const { polling } = await fetchJson(url);

    return {
      retryAfter: polling.retry_after,
      maxRetries: polling.max_retries,
    };
  } catch (e) {
    return false;
  }
}

async function getAnalysis(url) {
  try {
    return await fetchJson(url);
  } catch (e) {
    return false;
  }
}

export async function startRealtimeAnalysis({ url, onProgress, polling }) {
  const { retryAfter, maxRetries } = polling;
  let attempt = 0;
  let result;

  const checkCondition = async resolve => {
    attempt += 1;

    if (isType('function', onProgress)) {
      onProgress((attempt / maxRetries) * 100);
    }

    result = await getAnalysis(url);

    if (!result) {
      resolve(false);
    } else if (result.template) {
      resolve(result);
    } else if (attempt < maxRetries) {
      setTimeout(checkCondition, retryAfter, resolve);
    } else {
      resolve({ status: 'timeout' });
    }
  };

  return new Promise(checkCondition);
}
