import styled from 'styled-components';
import Box from '@nubank/nuds-web/components/Box/Box';

export const StyledBox = styled(Box)`
/* stylelint-disable property-no-vendor-prefix, property-no-unknown */
  p, span {
      background: ${props => (props.firstColor)};
      display: inline-block;
      font-size: ${props => (props.fontSize)};
      font-weight: bold;   
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
  }

  p:first-of-type {
    font-size: ${props => (props.firstFontSize)};
    font-weight: bold;
  }

  p:nth-child(2) {
    font-size: ${props => (props.dotSize)};
  }

  p:not(:first-of-type) {
    font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
  }
`;

StyledBox.displayName = 'StyledBox';
