import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Form from '@nubank/nuds-web/components/Form/Form';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';
import Icon from '@nubank/nuds-web/components/Icon/Icon';

import Image from '@nubank/www-latam-commons/components/Image/Image';

import { redirectionEvent } from '../../tracking';

// import { useSiteContext } from '../../../../components/SiteContext/SiteContext';

const StyledTitle = styled(Typography)`
  font-size: 28px;
`;

const Subtitle = styled(Typography)`
  font-size: 18px;
`;

const StyledButton = styled(Button)`
  background: #1111111A;
  color: #000;

  span {
    margin-top: -8px;
  }

  span > svg {
    margin-left: -20px;
    margin-right: 10px;
    transform: rotate(180deg) scaleY(1.2) scaleX(1.2) translateY(-3px);
  }
`;

function PhoneMismatch({ setShowPhoneMismatch }) {
  const [toNextScreen, setToNextScreen] = useState(false);

  const handleSubmit = ({ nextStep }) => {
    if (toNextScreen) {
      redirectionEvent('new request');
      nextStep();
      return;
    }

    redirectionEvent('mismatch retry');
    setShowPhoneMismatch(false);
  };

  return (
    <Form.Step
      onSubmit={handleSubmit}
    >
      {() => (
        <Box minHeight="100%">
          <Box
            overflowY="auto"
            paddingTop={{ xs: '24px', lg: '40px' }}
            paddingBottom="4x"
            paddingHorizontal={{ xs: '24px', lg: '40px' }}
            position="absolute"
            minHeight="-webkit-fill-available"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            <Box>
              <Image
                src="data-mismatch/missing-info.svg"
                alt="Ilustración de documento con un signo de exclamación"
                webp={false}
                width="150px"
                height="auto"
                loading="lazy"
              />
              {/* TITLE AND SUBTITLE */}
              <StyledTitle variant="heading2" marginTop="8x">
                ¡Oh no! No te encontramos,
                <StyledTitle tag="span" variant="heading2" color="primary">
                  {' '}
                  reintenta o inicia otra solicitud
                </StyledTitle>
              </StyledTitle>
              <Subtitle variant="paragraph1" marginTop="6x">
                Al reintentar,
                <Subtitle tag="span" variant="paragraph1" color="primary" strong>
                  {' '}
                  usa el mismo correo y número de celular que nos diste al inicio de tu solicitud.
                </Subtitle>
              </Subtitle>
            </Box>

            {/* BUTTONS */}
            <Box
              display="flex"
              marginTop="12x"
            >
              <StyledButton
                id="phone-mismatch-try-again-btn"
                variant="contained"
                styleVariant="primary"
                type="submit"
                extended
                onClick={() => setToNextScreen(false)}
              >
                <Icon name="chevron-right" title="Reintentar" />
                Reintentar
              </StyledButton>
              <Box width="30px" />
              <Button
                id="phone-mismatch-new-btn"
                variant="contained"
                styleVariant="primary"
                type="submit"
                extended
                onClick={() => setToNextScreen(true)}
              >
                Nueva solicitud
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </Form.Step>

  );
}

PhoneMismatch.propTypes = {
  setShowPhoneMismatch: PropTypes.func.isRequired,
};

export default PhoneMismatch;
