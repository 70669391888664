import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import Button from '@nubank/nuds-web/components/Button/Button';
import Checkbox from '@nubank/nuds-web/components/Checkbox/Checkbox';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import Image from '@nubank/nuds-web/components/Image/Image';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import { productChoiceEvent } from '../../tracking';
import { useSiteContext } from '../../../../components/SiteContext/SiteContext';

const productsOptions = [
  {
    id: 'product-credit-card',
    label: 'Tarjeta de Crédito Nu:',
    description: '$0 anualidad, MSI y opciones para diferir tus pagos.',
  },
  {
    id: 'product-cuenta',
    label: 'Cuenta Nu:',
    description: 'Débito y ahorros que crecen 12.50% anualmente.',
  },
  {
    id: 'product-secured-card',
    label: 'Tarjeta Garantizada Nu:',
    description: 'Tarjeta para mejorar o empezar tu historial crediticio.',
  },
];

const StyledDrawer = styled(Drawer)`
  #product-choice-drawer {
    bottom: 0;
    top: auto;
    border-radius: 16px 16px 0 0;
    padding: 12px;
    max-width: 480px;

    ${breakpointsMedia({
    xs: css`
      height: 93%;
    `,
    lg: css`
      height: 85%;
      min-width: 480px;
    `,
  })}
  }
`;

const StyledTitle = styled(Typography)`
  span {
    color: #820bd1;
  }

  ${breakpointsMedia({
    xs: css`
      font-size: 28px;
    `,
    lg: css`
      font-size: 38px;
    `,
  })}
`;

const SubTitle = styled(Typography)`
  font-size: 16px;
  
  span {
    font-weight: bold;
  }
`;

const StyledTypography = styled(Typography)`
  font-weight: bold;
  
  span {
    color: #820bd1;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  background: ${props => (props.active ? '#820AD1' : '#EFEFEF')};
  border-radius: 10px;
  padding: 12px;

  label > span, label > span:checked, label > span:hover,
  label > span:active, label > span:focus,
  label > span:visited, label > span:focus-visible,
  label > span:target {
    background: ${props => (props.active ? '#820AD1' : '#EFEFEF')};
    color: ${props => (props.active ? 'white' : 'black')};
    font-size: 14px;
  }
 
  span > svg {
    background: ${props => (props.active ? '#820AD1' : '#EFEFEF')};
    border: 1px solid ${props => (props.active ? '#820AD1' : '#EFEFEF')};
    border-radius: 5px;
    color: ${props => (props.active ? '#820AD1' : 'black')};
  }

  span > svg > g > path {
    color: ${props => (props.active ? 'white' : 'auto')};
  }

  ${breakpointsMedia({
    xs: css`
      margin-bottom: 10px;

      span > svg {
        margin-top: 100%;
      }
    `,
    lg: css`
      margin-bottom: 20px;

      span > svg {
        margin-top: 40%;
      }
    `,
  })}
`;

function ProductChoice({
  showProductChoiceModal, setShowProductChoiceModal,
}) {
  const [currentValues, setCurrentValues] = useState({
    'product-credit-card': false,
    'product-cuenta': false,
    'product-secured-card': false,
  });

  const {
    openRegistrationForm,
    updateDisplayPopUpContext,
    setProductChoiceSelection,
  } = useSiteContext();

  useEffect(() => {
    if (showProductChoiceModal) {
      productChoiceEvent('displayed');
      updateDisplayPopUpContext(false);
    }
  }, [showProductChoiceModal]);

  const handleSubmit = async btnSelected => {
    // BE Keys
    // product-credit-card, product-cuenta, product-secured-card

    setShowProductChoiceModal(false);
    const selectedProducts = Object.keys(currentValues).filter(key => currentValues[key]);

    // Save the selected products in the context
    // to send them to the BE after the prospect id is created
    setProductChoiceSelection(selectedProducts);

    if (btnSelected === 'products') {
      productChoiceEvent('selected');
      selectedProducts.forEach(product => {
        productChoiceEvent(product);
      });
    } else {
      productChoiceEvent('skipped');
    }

    openRegistrationForm();
    productChoiceEvent('sent');
  };

  return (
    <StyledDrawer
      width="100%"
      id="product-choice-drawer"
      direction="bottom"
      desktopDirection="bottom"
      open={showProductChoiceModal}
      onClose={() => {}}
    >
      {({ DrawerContent }) => (
        <DrawerContent>
          <Box
            paddingHorizontal="4x"
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="inherit"
          >

            <Box marginTop={{ xs: '4x', lg: '8x' }}>
              <Image
                alt="Icono de una tarjeta"
                src="product-choice/cards.png"
                webp={false}
                width={{ xs: '70px', md: '120px' }}
              />
              <StyledTitle
                variant="heading4"
                marginTop={{ xs: '2x', lg: '4x' }}
                marginBottom={{ xs: '0x', lg: '6x' }}
              >
                <span>Cuéntanos</span>
                {' '}
                qué
                <Box>
                  productos te interesan
                </Box>
              </StyledTitle>
              <SubTitle
                variant="subtitle1"
                marginTop="4x"
                marginBottom={{ xs: '0x', lg: '6x' }}
              >
                Queremos entender tus necesidades
                <span>
                  {' '}
                  para hacerte una oferta personalizada.
                </span>
              </SubTitle>

              {/* OPTIONS */}
              <Box
                role="group"
                aria-labelledby="checkbox-group"
                marginVertical="4x"
              >
                {productsOptions.map(product => (
                  <StyledCheckbox
                    key={product.id}
                    id={product.id}
                    name={product.id}
                    label={(
                      <Box
                        marginLeft="5px"
                        maxWidth={{ xs: '250px', lg: '500px' }}
                      >
                        <StyledTypography variant="paragraph2" tag="span">
                          {product.label}
                        </StyledTypography>
                        <Typography variant="paragraph2" tag="p">
                          {product.description}
                        </Typography>
                      </Box>
                        )}
                    active={currentValues[product.id]}
                    onClick={() => setCurrentValues({
                      ...currentValues,
                      [product.id]: !currentValues[product.id],
                    })}
                  />
                ))}
              </Box>

            </Box>

            <Box marginTop="4x">
              <Button
                id="product-choice-btn"
                variant="contained"
                styleVariant="primary"
                extended
                onClick={() => handleSubmit('products', currentValues)}
                disabled={!currentValues['product-credit-card'] && !currentValues['product-cuenta']
                      && !currentValues['product-secured-card']}
              >
                Continuar
              </Button>
              <Button
                id="product-choice-submit-btn"
                variant="contained"
                styleVariant="white"
                extended
                onClick={() => handleSubmit('skip')}
                disabled={currentValues['product-credit-card'] || currentValues['product-cuenta']
                  || currentValues['product-secured-card']}
              >
                Omitir
              </Button>
            </Box>
          </Box>
        </DrawerContent>
      )}
    </StyledDrawer>
  );
}

ProductChoice.propTypes = {
  setShowProductChoiceModal: PropTypes.func.isRequired,
  showProductChoiceModal: PropTypes.bool.isRequired,
};

export default ProductChoice;
