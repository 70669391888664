import React, {
  useRef, useEffect, useCallback, useState,
} from 'react';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import Form from '@nubank/nuds-web/components/Form/Form';
import Button from '@nubank/nuds-web/components/Button/Button';
import Link from '@nubank/nuds-web/components/Link/Link';
import styled from 'styled-components';

import { useSiteContext } from '../../../../components/SiteContext/SiteContext';
import { registerCandidateData } from '../../../../domains/candidate/candidate';
import { validateCandidateData } from '../../../../domains/redirection/flowRedirection';
import { ERROR_SEVERITY, sentryException } from '../../../../utils/sentry';
import MexicoFlag from '../../../../components/MexicoFlag/MexicoFlag';
import { contactDataStepRegisterEvent, displayedFunnelScreensEvent, redirectionEvent } from '../../tracking';
import Spinner from '../../../../components/Spinner/Spinner';
import AlreadyCustomer from '../DataMismatch/AlreadyCustomer';
import PhoneMismatch from '../DataMismatch/PhoneMismatch';

const NOT_A_NUMBER_REGEX = /\D/g;

const StyledTitle = styled(Typography)`
  font-size: 2rem;

  @media (width >= 768px) {
    font-size: 2.5rem;
  }
`;

const StyledSection = styled(Box)`
  button {
    min-width: 64px;
    height: 64px;
    margin-left: 18px;
  }

  span {
    color: #820AD1;
    font-weight: bold;
  }
`;

const ContactDataRedirection = () => {
  const emailInputRef = useRef();
  const emailConfirmInputRef = useRef();
  const [showAlreadyCustomerModal, setShowAlreadyCustomerModal] = useState(false);
  const [showPhoneMismatch, setShowPhoneMismatch] = useState(false);
  const [showRTRScreen, setShowRTRScreen] = useState(false);

  const {
    email, setEmail,
    setPhone, phone,
    registrationFlowEvents, updateRegistrationFlowEvents,
    discoveryUrlsList,
  } = useSiteContext();

  useEffect(() => {
    // Focus on email confirm input only if the email is already filled
    if (email) {
      emailConfirmInputRef.current?.focus();
    } else {
      emailInputRef.current?.focus();
    }

    if (!registrationFlowEvents.contactDataDisplayed) {
      displayedFunnelScreensEvent('contact data');
      updateRegistrationFlowEvents({ ...registrationFlowEvents, contactDataDisplayed: true });
    }
  }, []);

  const goToTheNextStep = (nextStep, values, setSubmitting) => {
    // Update the registration flow events for the main flow
    if (!registrationFlowEvents.contactData) {
      contactDataStepRegisterEvent();
      updateRegistrationFlowEvents({ ...registrationFlowEvents, contactData: true });
    }

    try {
      registerCandidateData(values.email, discoveryUrlsList);
    } catch (error) {
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'contact_data_step',
        namespace: '<ContactDataStep />',
        level: ERROR_SEVERITY.ERROR,
      });
    }

    setSubmitting(false);
    nextStep();
  };

  const redirectionFlow = (response, nextStep, values, setSubmitting) => {
    // Already prospect realtime-response-screen
    if (response === 'realtime-response') {
      setShowRTRScreen(true);
      redirectionEvent('rtr');
      return;
    }

    // Already customer already-customer-screen
    if (response === 'already-customer') {
      setShowAlreadyCustomerModal(true);
      redirectionEvent('customer');
      return;
    }

    // Mismatch scren information-mismatch-screen
    if (response === 'information-mismatch') {
      setShowPhoneMismatch(true);
      redirectionEvent('mismatch');
      return;
    }

    // Happy Flow default-screen
    if (response === 'default-screen') {
      goToTheNextStep(nextStep, values, setSubmitting);
    }
  };

  const handleSubmit = async ({
    values,
    setSubmitting,
    nextStep,
  }) => {
    // We are saving email again, to be sure we save the correct email after the confirmation input
    setEmail(values.email);
    if (!phone || phone !== values.phone) {
      setPhone(values.phone);
    }

    try {
      const payload = {
        phone: values.phone,
        email: values.email,
        urls: discoveryUrlsList,
      };

      const response = await validateCandidateData(payload);
      redirectionFlow(response, nextStep, values, setSubmitting);
    } catch (error) {
      sentryException({
        error,
        flow: 'application_flow',
        checkpoint: 'contact_data_redirection',
        namespace: '<ContactDataRedirection />',
        level: ERROR_SEVERITY.ERROR,
      });
    }
  };

  const inputMask = useCallback(value => {
    const valueWithOnlyNumbers = value.replace(NOT_A_NUMBER_REGEX, '');
    let maskedValue = valueWithOnlyNumbers;

    if (value.length >= 3) {
      maskedValue = `${maskedValue.substring(0, 2)} ${maskedValue.substring(2, maskedValue.length)}`;

      if (value.length >= 8) {
        maskedValue = `${maskedValue.substring(0, 7)} ${maskedValue.substring(7, maskedValue.length)}`;
      }
    }
    return maskedValue.trim();
  }, []);

  if (showPhoneMismatch) {
    return <PhoneMismatch setShowPhoneMismatch={setShowPhoneMismatch} />;
  }

  if (showRTRScreen) {
    return <PhoneMismatch setShowPhoneMismatch={setShowPhoneMismatch} />;
  }

  return (
    <Form.Step
      initialValues={{ email, phone }}
      initialTouched={{ email, phone }}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isDisabled, isSubmitting, setFieldValue }) => {
        const handlePhoneOnChange = ev => {
          const { target: { value } } = ev;
          const maskedValue = inputMask(value);

          setFieldValue('phone', maskedValue);
        };

        return (

          <Box
            padding={{ xs: '1.5rem', lg: '2.5rem' }}
            overflow="auto"
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <StyledTitle
              variant="heading3"
              marginBottom="4x"
            >
              Empieza tu solicitud con estos datos
            </StyledTitle>

            {/* EMAIL INPUT */}

            <TextField
              id="email"
              name="email"
              type="email"
              ref={emailInputRef}
              label="Escribe tu correo electrónico"
              syncValidations={{
                required: 'Escribe una dirección de correo válida',
                email: 'Escribe una dirección de correo válida',
              }}
            />

            {/* CONFIRM EMAIL */}

            <TextField
              id="confirmEmail"
              type="email"
              name="confirmEmail"
              ref={emailConfirmInputRef}
              tabIndex="0"
              autoComplete="off"
              label="Confirma tu correo electrónico"
              syncValidations={{
                required: 'Escribe una dirección de correo válida',
                email: 'Escribe una dirección de correo válida',
                equalsTo: { errorMsg: 'Tu correo electrónico no coincide', params: { target: 'email' } },
              }}
            />

            {/* PHONE INPUT */}

            <Box display="flex" flexDirection="column" width="100%">
              <Box display="flex">
                <Box
                  display="flex"
                  whiteSpace="nowrap"
                  width="76px"
                  height="32px"
                  marginTop="1.25rem"
                  backgroundColor="white.dark"
                  alignItems="center"
                  justifyContent="space-between"
                  borderRadius="12px"
                  marginRight="2x"
                  padding="1x"
                >
                  <MexicoFlag />
                  <Typography variant="paragraph1" strong color="black.defaultT70">
                    +52
                  </Typography>
                </Box>

                <Box width="100%">
                  <TextField
                    id="phone"
                    name="phone"
                    label="Escribe los 10 dígitos de tu número celular"
                    syncValidations={{
                      required: 'El teléfono debe incluir 10 números',
                      phone: 'El teléfono debe incluir 10 números',
                    }}
                    type="tel"
                    onChange={handlePhoneOnChange}
                    maxLength="12"
                  />
                </Box>
              </Box>

            </Box>

            {/* DISCLAIMER GRAY BOX */}

            <Box
              backgroundColor="#EFEFEF"
              padding="20px 0px 12px 0px"
              borderRadius="12px"
            >
              <Typography
                marginLeft="20px"
                variant="paragraph2"
                strong
                whiteSpace="pre-line"
              >
                {'🔒 Toda tu información estará segura.  \n Recuerda que Nu es supervisado por \n Condusef y CNBV.'}
              </Typography>
            </Box>

            {/* BOTTOM DISCLAIMER AND BTN */}

            <StyledSection
              display="flex"
              alignItems="center"
              marginTop="auto"
            >
              <Typography
                variant="paragraph2"
                strong
                intlKey="PROSPECT_REGISTRATION_FORM.CONTACT_DATA_STEP.DISCLAIMER"
                marginBottom="4x"
                intlValues={{
                  privacyLink: (
                    <Link
                      href="/aviso-de-privacidad-clientes-y-prospectos"
                      target="_blank"
                      typographyProps={{ variant: 'paragraph2', strong: true }}
                      intlKey="PROSPECT_REGISTRATION_FORM.CONTACT_DATA_STEP.PRIVACY_MESSAGE.LINK_LABEL"
                    />
                  ),
                }}
              />

              {isSubmitting ? (
                <Button
                  variant="contained"
                  styleVariant="primary"
                  disabled
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  id="contact-data-step-submit-btn"
                  variant="contained"
                  styleVariant="primary"
                  type="submit"
                  iconProps={{ name: 'arrow-right', title: 'Continuar' }}
                  disabled={isDisabled || isSubmitting}
                />
              )}

            </StyledSection>

            <AlreadyCustomer
              showAlreadyCustomerModal={showAlreadyCustomerModal}
              setShowAlreadyCustomerModal={setShowAlreadyCustomerModal}
            />

          </Box>
        );
      }}
    </Form.Step>
  );
};

export default ContactDataRedirection;
