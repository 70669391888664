import { fetchJson } from '@nubank/www-latam-commons/utils/http';

export async function getPublishedOffers(publishedOffersLink) {
  try {
    const response = await fetchJson(publishedOffersLink);

    return response;
  } catch (error) {
    console.error('error from getPublishedOffers in prospect', error);
    return false;
  }
}
