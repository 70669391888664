import React from 'react';
import { useIntl } from 'react-intl';
import styled from 'styled-components';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import PropTypes from 'prop-types';

import { downloadAppBtnLP, redirectionEvent } from '../../tracking';
import AppStoreBadge from '../../../../components/AppStoreBadge/AppStoreBadge';

const StyledDrawer = styled(Drawer)`
  #already-customer-drawer {
    bottom: 0;
    top: auto;
    height: 460px;
    border-radius: 16px 16px 0 0;
    padding: 12px;
  }
`;

const StyledTitle = styled(Typography)`
  font-size: 28px;
`;

const StyledTypography = styled(Typography)`
  font-weight: bold;
  
  span {
    color: #820bd1;
  }
`;

const StyledList = styled(Box)`
  list-style-type: disc;
  padding-left: 20px;
`;

const StyledListItem = styled.li`
  margin-bottom: 2px;
`;

function AlreadyCustomer({
  setShowAlreadyCustomerModal, showAlreadyCustomerModal,
}) {
  const handleSubmit = async () => {
    redirectionEvent('customer app');
    // TBD - Redirect to login page
  };
  const { formatMessage } = useIntl();

  const redirectToStore = (store, operatingSystem, page) => {
    window.open(formatMessage({ id: `HOME.DOWNLOAD_APP.${store}.PATH` }), '_blank');
    redirectionEvent('customer app');
    downloadAppBtnLP(operatingSystem, page);
  };

  const closeModal = () => {
    redirectionEvent('customer close btn');
    setShowAlreadyCustomerModal(false);
  };

  return (
    <StyledDrawer
      width="100%"
      id="already-customer-drawer"
      direction="bottom"
      desktopDirection="bottom"
      open={showAlreadyCustomerModal}
      onClose={() => closeModal()}
    >
      {({ DrawerContent, DrawerCloseButton }) => (
        <DrawerContent>
          <Box>
            <DrawerCloseButton onClick={() => closeModal()} />
          </Box>

          <Box paddingHorizontal="4x">

            <Box>
              <StyledTitle variant="heading4" color="primary">
                ¡Ya eres cliente Nu!
              </StyledTitle>
              <StyledTitle variant="heading4">
                Solicita nuevos productos desde tu app
              </StyledTitle>
              <Typography variant="subtitle1" marginTop="2x">
                Sigue estos pasos para que no empieces tu solicitud desde cero:
              </Typography>

              <StyledList tag="ul" marginTop="4x" marginBottom="12x">
                <StyledListItem>
                  <Typography variant="paragraph1">
                    Inicia sesión en la app.
                  </Typography>
                </StyledListItem>
                <StyledListItem>
                  <StyledTypography variant="paragraph1">
                    Revisa qué
                    <span>
                      {' '}
                      productos puedes solicitar en la pantalla principal.
                    </span>
                  </StyledTypography>
                </StyledListItem>
              </StyledList>

            </Box>

            <Box display={{ xs: 'block', md: 'none' }}>
              <Button
                id="already-customer-btn"
                type="button"
                variant="contained"
                styleVariant="primary"
                extended
                onClick={handleSubmit}
              >
                Iniciar sesión
              </Button>
            </Box>
            <Box
              display={{ xs: 'none', md: 'flex' }}
              justifyContent="space-evenly"
            >
              <Box onClick={() => redirectToStore('APPLESTORE', 'ios', 'already-customer')}>
                <AppStoreBadge platform="ios" variant="dark" />
              </Box>
              <Box onClick={() => redirectToStore('GOOGLESTORE', 'android', 'already-customer')}>
                <AppStoreBadge platform="android" variant="dark" />
              </Box>
            </Box>

          </Box>

        </DrawerContent>
      )}
    </StyledDrawer>
  );
}

AlreadyCustomer.propTypes = {
  setShowAlreadyCustomerModal: PropTypes.func.isRequired,
  showAlreadyCustomerModal: PropTypes.bool.isRequired,
};

export default AlreadyCustomer;
