import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@nubank/nuds-web/components/Button/Button';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';
import styled from 'styled-components';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import { useSiteContext } from '../../../../components/SiteContext/SiteContext';

const REDIRECTION_EXPERIMENT_ID = '347676';
const REDIRECTION_EXPERIMENT_VARIANT = '46990';

const StyledBox = styled(Box)`
  margin-left: -8px;
  margin-right: -12px;
`;

const FormHeader = ({ currentStep, totalSteps, prevStep }) => {
  const redirectionVariant = useFigPiiExperiment(REDIRECTION_EXPERIMENT_ID);

  const { closeRegistrationForm } = useSiteContext();

  const isTheFirstStep = currentStep === 1;
  const isTheLastStep = currentStep === totalSteps;

  const calculateStepProgress = step => step * (100 / totalSteps);

  const createButtonRef = useRef(null);

  useEffect(() => {
    if (createButtonRef && createButtonRef.current) {
      createButtonRef.current.focus();
    }
  }, []);

  const handleOnClose = () => {
    closeRegistrationForm(true);
  };

  if (isTheLastStep) {
    return null;
  }

  return (

    <Box
      tag="header"
      display="flex"
      flexDirection="column"
      paddingHorizontal={{ xs: '4x', lg: '40px' }}
      paddingBottom="4x"
      paddingTop="2x"
      height="5rem"
    >
      <StyledBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div data-testid="prev-step-container">
          {!isTheFirstStep && (
            <Button
              variant="basic"
              styleVariant="black"
              onClick={prevStep}
              iconProps={{
                name: 'arrow-left', title: 'Regresar',
              }}
            />
          )}
        </div>

        <Button
          buttonRef={createButtonRef}
          data-testid="close-btn"
          variant="basic"
          styleVariant="black"
          onClick={handleOnClose}
          iconProps={{
            name: 'x', title: 'Cerrar',
          }}
          size="default"
        />

      </StyledBox>

      {
        redirectionVariant !== REDIRECTION_EXPERIMENT_VARIANT && (
          <Box>
            <Box
              alignSelf="end"
              marginBottom="2x"
              paddingRight="1x"
            >
              <Typography variant="caption" color="primary" strong>
                {`Paso ${currentStep}`}
              </Typography>
              <Typography variant="caption" color="#727683">
                {`/${totalSteps - 1}`}
              </Typography>
            </Box>

            <Box
              width="100%"
              alignSelf="center"
              paddingRight="2x"
              paddingLeft="1x"
            >
              <ProgressBar
                percentage={calculateStepProgress(currentStep)}
                height="4px"
                borderRadius="8"
              />
            </Box>
          </Box>
        )
      }
    </Box>

  );
};

FormHeader.propTypes = {
  currentStep: PropTypes.number.isRequired,
  prevStep: PropTypes.func.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

export default FormHeader;
