import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';

import useFigPiiExperiment from '@nubank/www-latam-commons/utils/figpii/useFigPiiExperiment';

import Menu from '../../MenuXP/MenuXP';
import { HeaderCTA } from '../../HeaderCTA/HeaderCTA';

import { ActionsWrapper } from './ActionsWrapper';
import { StyledChip } from './StyledChip';
import MenuSocialMediaIcons from './MenuSocialMediaIcons';

const MGM_HERO_EXPERIMENT_ID = '348566';
const MGM_HERO_EXPERIMENT_VARIANT_B_ID = '48386';

const navigation = [
  {
    id: 1,
    title: 'Tarjeta de Crédito',
    href: '/credito',
    type: 'menu_link',
  },
  {
    id: 2,
    title: 'Cuenta Nu',
    href: '/cuenta',
    type: 'menu_link',
  },
  {
    id: 3,
    title: 'Tarjeta Garantizada',
    href: '/tarjeta-garantizada',
    type: 'menu_link',
  },
  {
    id: 4,
    title: 'Préstamos Personales',
    href: '/prestamo-personal',
    type: 'menu_link',
  },
  {
    id: 5,
    title: 'Beneficios',
    type: 'menu_column',
    subcategories: [
      {
        title: 'Beneficios de Nu',
        links: [
          {
            title: 'MSI y Aliados',
            href: '/meses-sin-intereses-con-nu/',
            new: false,
            newChipLabel: 'Nuevo',
          },
          {
            title: 'Promociones',
            href: '/promociones/',
            new: false,
            newChipLabel: 'Nuevo',
          },
        ],
      },
    ],
  },
  {
    id: 6,
    title: 'Nu',
    type: 'menu_column',
    subcategories: [
      {
        title: 'Nu global',
        links: [
          {
            title: 'Sobre Nu',
            href: 'https://international.nubank.com.br/es/sobre-nu/',
            new: false,
            newChipLabel: 'Nuevo',
          },
          {
            title: 'Trabaja en Nu',
            href: 'https://international.nubank.com.br/es/carreras/',
            new: false,
            newChipLabel: 'Nuevo',
          },
        ],
      },
    ],
  },
  {
    id: 7,
    title: 'Contacto y Ayuda',
    type: 'menu_column',
    subcategories: [
      {
        title: 'Ayuda Nu',
        links: [
          {
            title: 'Preguntas Frecuentes',
            href: '/ayuda',
            new: false,
            newChipLabel: 'Nuevo',
          },
          {
            title: 'Atención a clientes 24/7',
            href: '/atencion-a-clientes',
            new: false,
          },
        ],
      },
    ],
  },
];

function MenuWrapper({
  hideCTAButton,
  customHeaderCTA,
  buttonLabel,
  btnVisibleScrolling,
  isWhiteColorHero,
}) {
  const router = useRouter();
  const { route } = router;
  const activeVariant = useFigPiiExperiment(MGM_HERO_EXPERIMENT_ID);

  return (
    <>
      <Menu
        currentRoutePath={route}
        mobileButtonIconTitle={{
          titleClose: 'Cerrar',
          titleOpen: 'Abrir',
        }}
        isWhiteColorHero={activeVariant === MGM_HERO_EXPERIMENT_VARIANT_B_ID
          ? false : isWhiteColorHero}
        customHeightBelowTheHero={activeVariant === MGM_HERO_EXPERIMENT_VARIANT_B_ID ? 10 : 200}
      >
        <Menu.Logo />

        <Menu.Actions>
          <ActionsWrapper
            height="100%"
            width="100%"
            minWidth="100px"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-end"
          >

            {customHeaderCTA || (
            <HeaderCTA
              isHidden={hideCTAButton}
              label={buttonLabel}
              btnVisibleScrolling={btnVisibleScrolling}
            />
            ) }

          </ActionsWrapper>
        </Menu.Actions>

        <Menu.ActionsSmallScreen>
          <MenuSocialMediaIcons />
        </Menu.ActionsSmallScreen>

        <Menu.Navigation>
          {({
            Section,
            SectionTitle,
            SectionLink,
            Column,
            ColumnTitle,
            ColumnLink,
          }) => (
            <>
              {
              navigation.map(nav => {
                if (nav.type === 'menu_link') {
                  return (
                    <Section key={nav.id}>
                      <SectionLink href={nav.href}>
                        {nav.title}
                      </SectionLink>
                    </Section>
                  );
                }

                return (
                  <Section key={nav.id}>
                    <SectionTitle>{nav.title}</SectionTitle>
                    {nav.subcategories.map(subcategory => (
                      <Column key={`${nav.id}-column`}>
                        <ColumnTitle>{subcategory.title}</ColumnTitle>
                        {subcategory.links.map(link => (
                          <ColumnLink
                            key={nav.id}
                            href={link.href}
                            afterComponent={() => link.new && (
                            <StyledChip
                              backgroundColor="primary.defaultL10"
                              text={link.newChipLabel}
                            />
                            )}
                          >
                            {link.title}
                          </ColumnLink>
                        ))}
                      </Column>
                    ))}
                  </Section>
                );
              })
            }
            </>
          )}
        </Menu.Navigation>

      </Menu>
    </>
  );
}

MenuWrapper.defaultProps = {
  buttonLabel: 'HOME.MULTIPRODUCT.HERO.CTA.BTN',
  btnVisibleScrolling: false,
  customHeaderCTA: false,
  isWhiteColorHero: true,
  hideCTAButton: false,
};

MenuWrapper.propTypes = {
  btnVisibleScrolling: PropTypes.bool,
  buttonLabel: PropTypes.string,
  customHeaderCTA: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.node]),
  hideCTAButton: PropTypes.bool,
  isWhiteColorHero: PropTypes.bool,
};

export default React.memo(MenuWrapper);
