export const useDynamicYieldValues = () => {
  const dynamicState = {
    values: {
      dynamicYield: '12.00',
      dynamicGATNominal: '12.75',
      dynamicGATReal: '8.61',
      // 7 DAYS
      dynamicYield7Days: '12.30',
      dynamicGATNominal7Days: '13.09',
      dynamicGATReal7Days: '8.94',
      // 28 DAYS
      dynamicYield28Days: '12.62',
      dynamicGATNominal28Days: '13.45',
      dynamicGATReal28Days: '9.28',
      // 90 days
      dynamicYield90Days: '14.00',
      dynamicGATNominal90Days: '15.02',
      dynamicGATReal90Days: '10.80',
      // 180 days
      dynamicYield180Days: '11.76',
      dynamicGATNominal180Days: '12.48',
      dynamicGATReal180Days: '8.35',
    },
    textTemplates: {
      VALUES_CALCULATED_AT: '20 de diciembre de 2024',
      MINIMUM_SAVINGS_AMOUNT: '$0.01',
      FROZEN_MINIMUM_SAVINGS_AMOUNT: '$50.00',
      VALID_UNTIL: '19 de febrero de 2025',
    },
  };

  return dynamicState;
};

export const FROZEN_TIME = {
  A_180_DAYS: 180,
  A_90_DAYS: 90,
  A_28_DAYS: 28,
  A_7_DAYS: 7,
  NO_FROZEN: 0,
};
