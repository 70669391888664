import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { fetchDiscoveryURLs } from '@nubank/www-latam-commons/domains/discovery/fetchDiscoveryURLs';

import { get as getCaptcha } from '../../utils/recaptcha';

export const validateCandidateData = async ({
  phone, email, taxId, urls,
}) => {
  if (!phone || !email) {
    throw new Error('Phone and mail expected');
  }

  const discoveryURLs = urls || await fetchDiscoveryURLs();
  const captcha = await getCaptcha();

  // eslint-disable-next-line camelcase
  if (!discoveryURLs?.web_start_screen) {
    throw new Error('Discovery fetch response unexpected - missing endpoint');
  }

  const payload = {
    phone,
    email,
    'tax-id': taxId,
  };

  const reCaptchaHeaders = {
    // 'x-recaptcha-token-type': 'enterprise' or 'v3'
    'x-recaptcha-token-type': 'enterprise',
    'x-recaptcha-token': captcha,
  };

  try {
    const result = await fetchJson(discoveryURLs.web_start_screen, {
      method: 'POST',
      body: payload,
      ...(captcha && { headers: reCaptchaHeaders }),
    });
    return result;
  } catch (error) {
    return error;
  }
};
