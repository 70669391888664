import React, {
  useState, useContext, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Box from '@nubank/nuds-web/components/Box/Box';
import { useBreakpointsMediaDown, useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

import Logo from './patterns/Logo/Logo';
import Actions from './patterns/Actions/Actions';
import ActionsSmallScreen from './patterns/ActionsSmallScreen/ActionsSmallScreen';
import { MenuContext } from './MenuContext';
import Navigation, { NavigationKeyToComponentMap } from './patterns/Navigation/Navigation';
import ButtonToggle from './patterns/ButtonToggle';
import {
  normalizeNavigationStructure,
  typeValidation,
  getChildrenByType,
  normalizeNavigationStructureComponents,
} from './utils';
import NavigationListSmallScreen from './patterns/Navigation/NavigationListSmallScreen/NavigationListSmallScreen';

const NavigationListLargeScreen = dynamic(
  () => import('./patterns/Navigation/NavigationListLargeScreen/NavigationListLargeScreen'),
  {
    loading: () => <Box width="100%" height="100%" backgroundColor="white.default" />,
    ssr: false,
  },
);

// THERE ARE 4 WAYS THE HEADER WILL BE  ACTIVE (WHITE)
// 1. When the user is hovering the header
// 2. When the mobile menu is open
// 3. When the user scrolled below the top of the page in a non-white hero
// 4. When the user is in a page with a white hero

// These values are used by the
// 1 - NavigationListLink component (wrapper of the links in the header)
// 2 - Logo component (the logo in the header)
// 3 - Menu component (the header itself)
// 4 - Tooggle menu icon (for mobile menu)

function Menu({
  children,
  mobileButtonIconTitle,
  onToggleMobileMenu,
  hideMobileMenu,
  currentRoutePath: routePath,
  isWhiteColorHero,
  customHeightBelowTheHero,
}) {
  const logoChildren = getChildrenByType(children, ['Logo']);
  const actionsChildren = getChildrenByType(children, ['Actions']);
  const actionsSmallScreenChildren = getChildrenByType(children, ['ActionsSmallScreen']);
  const [navigationChildren] = getChildrenByType(children, ['Navigation']);
  const navigationComponentStructure = navigationChildren
    ? normalizeNavigationStructureComponents(navigationChildren
      .props
      .children(NavigationKeyToComponentMap)
      .props
      .children)
    : [];

  const { isMobileNavOpen: initialIsMobileNavOpen } = useContext(MenuContext);
  const menuWrapperRef = useRef(null);
  const menuNavigationStructure = normalizeNavigationStructure(navigationComponentStructure);
  const [navigationSectionActive, setNavigationSectionActive] = useState('');
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(initialIsMobileNavOpen);
  const [currentRoutePath, currentsetRoutePath] = useState(routePath);
  const [isServer] = useState(typeof window === 'undefined');
  const [isHovered, setIsHovered] = useState(false);
  const [userScrolledBelowTheHero, setUserScrolledBelowTheHero] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileNavOpen(prevValue => {
      onToggleMobileMenu(!prevValue);
      return !prevValue;
    });
  };

  useEffect(() => {
    if (routePath) {
      currentsetRoutePath(routePath);
    }
  }, [routePath]);

  const breakpointsDownLG = isServer ? true : useBreakpointsMediaDown('lg');
  const breakpointsUpLG = isServer ? false : useBreakpointsMediaUp('lg');

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const detectUserScroll = () => {
    if (isWhiteColorHero || isMobileNavOpen) return;

    if (window.scrollY > customHeightBelowTheHero) {
      setUserScrolledBelowTheHero(true);
    } else {
      setUserScrolledBelowTheHero(false);
    }
  };

  useEffect(() => {
    if (!isServer) {
      window.addEventListener('scroll', detectUserScroll);
    }

    return () => {
      window.removeEventListener('scroll', detectUserScroll);
    };
    // RETURN TO EMPTY AFTER EXPERIMENT
  }, [isWhiteColorHero]);

  useEffect(() => {
    if (breakpointsUpLG) {
      setIsMobileNavOpen(false);
    }
  }, [breakpointsUpLG]);

  return (
    <MenuContext.Provider
      value={{
        navigationSectionActive,
        setNavigationSectionActive,
        menuNavigationStructure,
        isMobileNavOpen,
        setIsMobileNavOpen,
        currentRoutePath,
        currentsetRoutePath,
        toggleMobileMenu,
        isHovered,
        setIsHovered,
        isWhiteColorHero,
        userScrolledBelowTheHero,
      }}
    >
      {navigationChildren}
      <Box
        ref={menuWrapperRef}
        width="100%"
        position="relative"
        boxShadow={`${isHovered || userScrolledBelowTheHero
          ? `0px 1px 1px ${nuDSColor('black', 'defaultT10')()}`
          : 'none'}`}
      >
        <Box
          id="menu-wrapper"
          width="100%"
          height={{
            xs: '64px',
            lg: '56px',
          }}
          paddingLeft={{
            xs: '6x',
            md: '8x',
            lg: '12x',
          }}
          paddingRight={{
            xs: '10px',
            md: '18px',
            lg: '12x',
          }}
          display="grid"
          gridTemplateColumns="min-content minmax(160px,1fr) minmax(min-content, auto)"
          gridGap={{
            xs: '2x',
            lg: '8x',
          }}
          alignItems="stretch"
          backgroundColor={isWhiteColorHero || isHovered || isMobileNavOpen || userScrolledBelowTheHero ? 'white.default' : 'transparent'}
          position="relative"
          zIndex={{
            xs: '2',
            lg: 'auto',
          }}
          onMouseEnter={breakpointsDownLG ? () => {} : handleMouseEnter}
          onMouseLeave={breakpointsDownLG ? () => {} : handleMouseLeave}
        >
          <Box
            width="auto"
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {logoChildren}
          </Box>

          {breakpointsUpLG && (
            <NavigationListLargeScreen componentsList={navigationComponentStructure} />
          )}

          <Box>{actionsChildren}</Box>

          {(!hideMobileMenu && breakpointsDownLG) && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <ButtonToggle
                onMenuToggleClick={toggleMobileMenu}
                isMobileNavOpen={isMobileNavOpen}
                iconProps={mobileButtonIconTitle}
              />
            </Box>
          )}
        </Box>

        {breakpointsDownLG && (
          <NavigationListSmallScreen
            listNavigationComponents={navigationComponentStructure}
            actionsSmallScreenChildren={actionsSmallScreenChildren}
            isHidden={!isMobileNavOpen}
          />
        )}

      </Box>
    </MenuContext.Provider>
  );
}

Menu.Logo = Logo;
Menu.Actions = Actions;
Menu.ActionsSmallScreen = ActionsSmallScreen;
Menu.Navigation = Navigation;

Menu.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  __TYPE: 'Menu',
  hideMobileMenu: false,
  // eslint-disable-next-line react/default-props-match-prop-types
  onToggleMobileMenu: () => { },
  currentRoutePath: undefined,
  customHeightBelowTheHero: 200,
};

Menu.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  __TYPE: typeValidation('Menu'),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  currentRoutePath: PropTypes.string,
  customHeightBelowTheHero: PropTypes.string,
  hideMobileMenu: PropTypes.bool,
  isWhiteColorHero: PropTypes.bool.isRequired,
  mobileButtonIconTitle: PropTypes.shape({
    titleClose: PropTypes.string,
    titleOpen: PropTypes.string,
  }).isRequired,
  onToggleMobileMenu: PropTypes.func,
};

export default Menu;
