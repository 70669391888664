import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Form from '@nubank/nuds-web/components/Form/Form';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Button from '@nubank/nuds-web/components/Button/Button';

import { useSiteContext } from '../SiteContext/SiteContext';
import { emailOnHeroRegisterEvent } from '../../screens/Registration/tracking';
import { ERROR_SEVERITY, sentryException } from '../../utils/sentry';
import { getDiscoveryUrls } from '../../domains/discoveryUrls/discoveryUrls';
import ProductChoice from '../../screens/Registration/patterns/FinancialGoals/ProductChoice';

// Experiment IDs
const FINANCIAL_GOALS_XP_ID = '344539';
const FINANCIAL_GOALS_XP__PRODUCT_VARIANT = '48086';

const emailPattern = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{1,})$/;

const StyledTypographyCTA = styled(Typography)`
  /* stylelint-disable property-no-vendor-prefix */
  background: ${props => (props.ctaGradient === 'default' ? props.color : props.ctaGradient)};
  color: ${props => (props.color)};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  background-color: #F4F4F4;
  font-size: 14px;

  @media (width >= 768px) {
    font-size: 20px;
  }

  &::placeholder {
    color: #777;
  }

  &.red-error {
    &::placeholder {
      color: #D72923;
    }

    p {
      display: none;
    }
  }

  :invalid, :valid, :-webkit-autofill, :autofill {
    border:  none;
    border-radius: 6.25rem;
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1.25rem;
  }

  :user-invalid {
    border: 2px solid #D72923;
  }
`;

const StyledButton = styled(Button)`
  background-color: ${props => (props.buttonBackgroundColor)};
  position: absolute;
  right: 8px;
  top: 22px;
  height: 36px;
  width: 36px;

  svg {
    width: 24px;
    height: 24px;
  }
`;

// To hide default svg icon
const StyledBox = styled(Box)`
  span {
    display: none;
  }

  p#error-email {
    margin-left: 12px;
    display: none;
  }
`;

const CustomBox = styled(Box)`
  div {
    padding: 8px 0 0;
    min-height: auto;
  }
`;

const StyledTypography = styled(Typography)`
  font-size: 16px;

  @media (width >= 768px) {
    font-size: 20px;
  }
`;

const ErrorMessage = styled(Typography)`
  color: #D01D1C;
  font-size: 12px;
  font-weight: bold;
  margin-top: -6px;
  margin-left: 18px;
`;

function FormBox({
  buttonBackgroundColor,
  buttonLabel,
  ctaColor,
  ctaFontSize,
  ctaGradient,
  ctaHide,
  ctaLabel,
  ctaMarginBottom,
  ctaMaxWidth,
  marginTop,
}) {
  // Experiment
  const [showProductChoiceModal, setShowProductChoiceModal] = useState(false);

  const {
    setEmail,
    openRegistrationForm,
    updateIsFormBoxAvailable,
    discoveryUrlsList,
    updateDiscoveryUrlsList,
  } = useSiteContext();

  useEffect(() => {
    updateIsFormBoxAvailable(true);
  }, [updateIsFormBoxAvailable]);

  // Display error message if email is invalid
  const [emailValue, setEmailValue] = useState('');
  const [pressedSend, setPressedSend] = useState(false);
  const [displayError, setDisplayError] = useState(false);

  const validateEmail = email => emailPattern.test(email);

  const startMailValidation = () => {
    if (pressedSend) {
      return;
    }
    setPressedSend(true);
    setDisplayError(!validateEmail(emailValue));
  };

  useEffect(() => {
    if (pressedSend) {
      setDisplayError(!validateEmail(emailValue));
    }
  }, [emailValue]);

  const handleSubmit = async ({
    values,
    setSubmitting,
  }) => {
    if (values.email) {
      try {
        setEmail(values.email);
        const figpiiInfo = window.FIGPII.experimentManager.getAllChosenVariations();
        if (figpiiInfo && Object.keys(figpiiInfo).length > 0
        && figpiiInfo[FINANCIAL_GOALS_XP_ID] === FINANCIAL_GOALS_XP__PRODUCT_VARIANT) {
          setShowProductChoiceModal(true);
        } else {
          openRegistrationForm();
        }

        if (!discoveryUrlsList) {
          const discoveryUrls = await getDiscoveryUrls();
          updateDiscoveryUrlsList(discoveryUrls);
        }
      } catch (error) {
        sentryException({
          error,
          flow: 'application_flow',
          checkpoint: 'set email and open registration form',
          namespace: '<FormBox/>',
          level: ERROR_SEVERITY.CRITICAL,
        });
      } finally {
        setSubmitting(false);

        emailOnHeroRegisterEvent();
      }
    } else {
      setSubmitting(false);
    }
  };

  return (
    <Box width="fit-content">
      <Form>
        <Form.Step
          initialValues={{ email: '' }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {() => (
            <Box
              display="flex"
              flexDirection="column"
              alignItems={{ xs: 'center', lg: 'flex-start' }}
            >
              {ctaHide ? null : (
                <StyledTypographyCTA
                  intlKey={ctaLabel}
                  variant={ctaFontSize}
                  color={ctaColor}
                  ctaGradient={ctaGradient}
                  marginBottom={ctaMarginBottom}
                  marginTop={{ xs: '0', md: '4x', lg: '0' }}
                  textAlign={{ xs: 'center', lg: 'left' }}
                  maxWidth={ctaMaxWidth}
                  strong
                />
              )}

              <Box width="100%">
                <CustomBox
                  backgroundColor="white.default"
                  width={{ xs: '300px', md: '400px' }}
                  height={{ xs: '130px', md: '140px', lg: '136px' }}
                  marginTop={marginTop}
                  borderRadius="24px"
                  paddingHorizontal="18px"
                  paddingTop="16px"
                >

                  <StyledTypography
                    intlKey={buttonLabel}
                    marginTop={{ xs: '0', md: '4x', lg: '0' }}
                    textAlign="left"
                    strong
                  />

                  <StyledBox
                    display="flex"
                    flexDirection="column"
                    position="relative"
                    width="100%"
                  >
                    <StyledTextField
                      id="email-on-hero"
                      type="email"
                      name="email"
                      className="email-1"
                      label=""
                      placeholder="Escribe tu correo electrónico"
                      syncValidations={{
                        required: 'Revisa que tu correo esté bien',
                        email: 'Revisa que tu correo esté bien',
                      }}
                      onInput={e => setEmailValue(e.target.value)}
                    />

                    <StyledButton
                      id="email-on-hero-submit-btn"
                      variant="contained"
                      type="submit"
                      size="small"
                      buttonBackgroundColor={buttonBackgroundColor}
                      iconProps={{ name: 'chevron-right', title: 'Continuar' }}
                      onClick={() => startMailValidation()}
                    />

                  </StyledBox>
                  { displayError && (
                  <Box>
                    <ErrorMessage variant="paragraph1">
                      Revisa que tu correo esté bien
                    </ErrorMessage>
                  </Box>
                  )}
                </CustomBox>
              </Box>

              <ProductChoice
                showProductChoiceModal={showProductChoiceModal}
                setShowProductChoiceModal={setShowProductChoiceModal}
              />

            </Box>
          )}
        </Form.Step>
      </Form>
    </Box>
  );
}

FormBox.defaultProps = {
  buttonBackgroundColor: 'default',
  buttonLabel: 'FORMBOX.DEFAULT.BUTTON_LABEL',
  ctaColor: 'white',
  ctaFontSize: 'heading4',
  ctaGradient: 'default',
  ctaHide: false,
  ctaLabel: 'FORMBOX.DEFAULT.CTA_LABEL',
  ctaMarginBottom: '2x',
  ctaMaxWidth: 'auto',
  marginTop: '24px',
};

FormBox.propTypes = {
  buttonBackgroundColor: PropTypes.string,
  buttonLabel: PropTypes.string,
  ctaColor: PropTypes.string,
  ctaFontSize: PropTypes.string,
  ctaGradient: PropTypes.string,
  ctaHide: PropTypes.bool,
  ctaLabel: PropTypes.string,
  ctaMarginBottom: PropTypes.string,
  ctaMaxWidth: PropTypes.string,
  marginTop: PropTypes.string,
};

export default FormBox;

